import { useTranslation } from "react-i18next";
import { CellContext } from "@tanstack/react-table";
import { FormattedFeed, OddEven } from "../../../types/feed";
import { OddPrice } from "../../../components/Typography";
import { CustomCellContext } from "../../../types/table";
import { isGoodTeam } from "../../../utils/isGoodTeam";
import { convertOdds } from "../../../utils/convertOdds";
import useOddChangeDetector from "../../../hooks/useOddChangeDetector";

const OE = (row: CellContext<FormattedFeed, unknown> & CustomCellContext) => {
  const { i18n } = useTranslation();
  const oddEven = row.getValue<OddEven>();
  const [leagueId, matchId] = row.row.original.match_id.split(":");
  const { match_n: matchName, tableType, sport } = row.row.original;
  const { homePriceChangedType, awayPriceChangedType } = useOddChangeDetector({
    homeHandicap: oddEven?.ok || "",
    awayHandicap: oddEven?.ek || "",
    homePrice: oddEven?.o || "0",
    awayPrice: oddEven?.e || "0",
  });

  if (!oddEven) {
    return "";
  }

  const odd = convertOdds(Number(oddEven?.o), row?.oddsType);
  const even = convertOdds(Number(oddEven?.e), row?.oddsType);

  return (
    <>
      <div className="flex mr-2">
        <div className="flex-1 font-bold text-xs mr-2 flex justify-center items-center">
          o
        </div>
        <div className="flex-1 text-right">
          <OddPrice
            className="relative"
            isRedOdd={odd.includes("-")}
            isIncrease={homePriceChangedType === "increase"}
            isDecrease={homePriceChangedType === "decrease"}
            onClick={() =>
              row?.onClickOddPrice?.({
                leagueId,
                matchId,
                option: 0,
                betType: "oe",
                sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
                table: tableType,
                type: row?.betType || "normal",
                home: matchName[i18n.language].h,
                away: matchName[i18n.language].a,
                isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
                isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
                league: row.row.original?.feed_nn,
                riskLevel: row.row.original?.betPrice_order,
                fhid: `${matchName.fhid}`,
                faid: `${matchName.faid}`,
                outcomeId: row.row.original?.betPrice_oe?.oco,
                specifiers: row.row.original?.betPrice_oe?.sp,
                marketId: row.row.original?.betPrice_oe?.mkid,
              })
            }
          >
            {odd}
          </OddPrice>
        </div>
      </div>
      <div className="flex mr-2">
        <div className="flex-1 font-bold text-xs mr-2 flex justify-center items-center">
          e
        </div>
        <div className="flex-1 text-right">
          <OddPrice
            className="relative"
            isRedOdd={even.includes("-")}
            isIncrease={awayPriceChangedType === "increase"}
            isDecrease={awayPriceChangedType === "decrease"}
            onClick={() =>
              row?.onClickOddPrice?.({
                leagueId,
                matchId,
                option: 1,
                betType: "oe",
                sportType: sport.toUpperCase() as "FOOTBALL" | "BASKETBALL",
                table: tableType,
                type: row?.betType || "normal",
                home: matchName[i18n.language].h,
                away: matchName[i18n.language].a,
                isHomeGood: isGoodTeam(row.row.original?.betPrice_ah?.h),
                isAwayGood: isGoodTeam(row.row.original?.betPrice_ah?.a),
                league: row.row.original?.feed_nn,
                riskLevel: row.row.original?.betPrice_order,
                fhid: `${matchName.fhid}`,
                faid: `${matchName.faid}`,
                outcomeId: row.row.original?.betPrice_oe?.oce,
                specifiers: row.row.original?.betPrice_oe?.sp,
                marketId: row.row.original?.betPrice_oe?.mkid,
              })
            }
          >
            {even}
          </OddPrice>
        </div>
      </div>
    </>
  );
};

export default OE;
