import { commaNumber } from "./commaNumber";

export const suffixNumber = (input: string | number) => {
  if (Number.isNaN(Number(input))) {
    return input;
  }
  const min = 100000;

  return Number(input) >= min
    ? `${commaNumber(Number(input) / 1000)}K`
    : commaNumber(input);
};
