import { FC, ReactNode } from "react";
import _ from "lodash";
import { TeamName, OddPrice } from "../../../../components/Typography";
import { suffixNumber } from "../../../../utils/suffixNumber";
import { LeagueName } from "../../../BetSlip/styled";
import { Wrapper, TeamMatchingWrapper } from "./styled";
import { Currency } from "../../../../types/member";
// import moment from "moment";

export type MatchProps = {
  date: string;
  homeTeam: string;
  isHomeTeamGood: boolean;
  isAwayTeamGood: boolean;
  awayTeam: string;
  league: string;
  sportType: string;
  betPriceType: string;
  betType: string;
  handicap: string;
  oddPrice: string;
  oddsType: string;
  amount: number;
  BettingTeamName: ReactNode;
  isLive?: boolean;
  liveScore?: string;
  currency?: Currency;
};

const Match: FC<MatchProps> = ({
  // date,
  homeTeam,
  isHomeTeamGood,
  isAwayTeamGood,
  awayTeam,
  league,
  sportType,
  betPriceType,
  betType,
  handicap,
  oddPrice,
  oddsType,
  amount,
  BettingTeamName,
  isLive = false,
  liveScore = "",
  currency = "THB",
}) => {
  return (
    <Wrapper isLive={isLive}>
      <TeamMatchingWrapper isLive={isLive}>
        <TeamName
          className="text-sm flex justify-center items-center"
          isGoodTeam={isHomeTeamGood}
        >
          {/* <img
            src={`https://livescore.narongchai-pla.workers.dev/${sportType.toLowerCase()}/team/${homeTeam.replaceAll(
              " ",
              "-"
            )}.png?date=${moment(date).format("YYYY-MM-DD")}&size=small`}
            alt=""
            className="w-5 h-5"
            onError={(event) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
            }}
          /> */}
          {homeTeam}
        </TeamName>
        vs
        <TeamName
          className="text-sm flex justify-center items-center"
          isGoodTeam={isAwayTeamGood}
        >
          {/* <img
            src={`https://livescore.narongchai-pla.workers.dev/${sportType.toLowerCase()}/team/${awayTeam.replaceAll(
              " ",
              "-"
            )}.png?date=${moment(date).format("YYYY-MM-DD")}&size=small`}
            alt=""
            className="w-5 h-5"
            onError={(event) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = `${process.env.PUBLIC_URL}/team.svg`;
            }}
          /> */}
          {awayTeam}
        </TeamName>
        <LeagueName className="p-2 text-xs font-bold">{league}</LeagueName>
      </TeamMatchingWrapper>
      {isLive && <span>Live@{liveScore}</span>}
      <div className="font-bold text-sm">{`${_.capitalize(
        sportType
      )} ${betPriceType}`}</div>
      {BettingTeamName}
      <div className="flex justify-between text-sm font-bold">
        <div>
          {handicap === "0" ? "" : handicap} @
          <OddPrice isRedOdd={`${oddPrice}`.includes("-")}>{oddPrice}</OddPrice>{" "}
          {oddsType}
        </div>
        {betType === "normal" && (
          <div>
            {currency} {suffixNumber(amount)}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default Match;
